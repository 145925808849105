@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.App {
  text-align: center;
  background-image: linear-gradient(to bottom, #534293, #6b47b1, #874acd, #a848e7, #cc41ff);
  min-height: 100vh;
  font-family: 'Source Code Pro', monospace;
}

.content {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 300px;
  height: 300px;
  display: inline-block;
  border: 20px solid whitesmoke;
  border-radius: 50%;
  margin-top: 2%;
}

h1 {
  color: whitesmoke;
}

.text-link {
  text-decoration: none;
  color: #E0E0E0;
}

.text-link:hover {
  text-decoration: underline;
}

.links {
  width: 800px;
  max-width: 96vw;
  display: flex;
  position: fixed;
  bottom: 0;
  margin-bottom: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.icon {
  opacity: .7;
}

.icon:hover {
  opacity: 1;
  transform: translateY(-10px);
  transition: transform .5s;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}